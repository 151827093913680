<template>
  <div
    v-click-outside="closeDropdown"
    class="action-dropdown"
  >
    <button
      class="action-button"
      @click="toggleDropdown"
    >
      <div class="profile">
        <div v-if="getLoggedInUser.gender == 'Male'">
          <img
            v-if="getLoggedInUser.userProfile == '' || getLoggedInUser.userProfile == null"
            src="@/assets/images/avatarMale.jpg"
            width="50px"
            height="50px"
            alt="test"
            style="margin-right: 5px;border-radius: 50%;"
          >
          <img
            v-else
            :src="getLoggedInUser.userProfile"
            width="50px"
            height="50px"
            alt="test"
            style="margin-right: 5px;border-radius: 50%;"
          >
        </div>
        <div v-else>
          <img
            v-if="getLoggedInUser.userProfile == '' || getLoggedInUser.userProfile == null"
            src="@/assets/images/avatarFemale.svg"
            width="50px"
            height="50px"
            alt="test"
            style="margin-right: 5px;border-radius: 50%;"
          >
          <img
            v-else
            :src="getLoggedInUser.userProfile"
            width="50px"
            height="50px"
            alt="test"
            style="margin-right: 5px;border-radius: 50%;"
          >
        </div>
        <span style="display: flex;flex-direction: column; padding-right: 10px;">
          <p class="username">
            {{ getLoggedInUser.firstName }} {{ getLoggedInUser.lastName }}
          </p>
          <span class="userrole">
            {{ getLoggedInUser.role }}
          </span>
        </span>
      </div>
    </button>

    <div
      v-show="showActionDropdown"
      ref="dropdown"
      class="dropdown-content"
      @mouseleave="closeDropdown"
    >
      <router-link
        to="/profile"
        class="router-link-wrapper"
        @click="closeDropdown"
      >
        <p>{{ $t('MyProfile') }}</p>
      </router-link>
      <p
        style="cursor: pointer;"
        @click="goToLogin"
      >
        {{ $t('LockSession') }}
      </p>
      <router-link
        to="/info"
        class="router-link-wrapper"
        @click="closeDropdown"
      >
        <p>{{ $t('About') }}</p>
      </router-link>
      <p
        style="cursor: pointer; border-top: 1px solid #E7E7E8; margin-bottom: 0px; padding-top: 5%;"
        @click="signOut"
      > <img
        src="../assets/images/logouticon.svg"
        alt=""
      >{{ $t('Logout') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/main'
import connection from '../microsoft-signalR/signalR'

export default {
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  props: ['showDropdown'],
  data() {
    return {
      dataFromSignup: null,
      showActionDropdown: false,
    }
  },
  created() {
    // Try to get data from localStorage on component creation
    const storedData = localStorage.getItem('signupData');
    if (storedData) {
      this.dataFromSignup = JSON.parse(storedData);
    }

    // Listen for the event on the event bus
    eventBus.$on('signup-data', (data) => {
      // Handle the emitted data
      this.dataFromSignup = data;

      // Save the data to  localStorage
      localStorage.setItem('signupData', JSON.stringify(data));
    });
  },
  computed: {
    ...mapGetters(['getLoggedInUser']),
  },
  methods: {
    ...mapActions(['logoutUser']),
    goToLogin() {
      this.logout();
      this.$router.push('/lockout');
      eventBus.$emit('signup-data', this.dataFromSignup);
      localStorage.setItem('signupData', JSON.stringify(this.dataFromSignup));
    },
    logout() {
      this.logoutUser().then(() => {
        this.$router
          .push({ path: '/lockout' })
      });
    },
    signOut() {
      connection.stop()
      this.logoutUser()
      localStorage.removeItem('signupData');
      localStorage.removeItem('modalShownBefore');
      this.$router
        .push({ path: '/login' })
        // .then(() => { this.$router.go() })
      this.onCancel()
    },
    toggleDropdown() {
      this.showActionDropdown = !this.showActionDropdown;
    },
    closeDropdown() {
      if (this.showActionDropdown) {
        this.showActionDropdown = false;
      }
    },
  },
}
</script>


<style scoped>
.action-button{
  border: none;
  background: white;
  color: black;
}
.profile {
  display:flex;
  align-items:center;
  justify-content:space-between;
  text-align: left;
  color: black;
  border-right: 1px solid #E7E7E8;
  width: 100%;
  border: none;
}
.dropdown {
  position: absolute;
  top: 50%;
  right: 21%;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 999;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 50;
  width: 200px;
  height: fit-content;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #E7E7E8;
  border-radius: 4px;
  padding: 16px;
  z-index: 9999;
}

.dropdown-content p {
  color: #1A1A1A;
  font-size: 16px;
  font-weight: 400;
}

.router-link-wrapper {
  text-decoration: none;
  color: inherit;
}

.username {
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-bottom: 0px;
}

.userrole {
  font-size: 14px;
  font-weight: 400;
  color: #B0B4B9;
}

.action-dropdown {
  display: flex;
  justify-content: flex-end;
}
</style>
