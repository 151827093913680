import axios from 'axios'
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';



/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const domain = api.masterDataDomain;

const state = {
  status: false,
  materialvariants: [],
  materialvariantsPDF: [],
  materialvariantsPDFQRCode: [],
  MatVarCurrentPage: 1,
  MatVarTotalItems: 0,
  filteredMV: [],
  filteredForSearch: [],
  inventoryMaterialVariants: [],
  inventoryMaterialVariantsId: [],
  currentPage: 1,
  totalItems: 0,
  totalItemsPages: 0,
  totalItemsColors: 0,
  currentPageColors: 1,
  colorsData: [],
  colorNames: [],
  materialVariantNames: [],
  materialVariantHistory: [],
  materialVariantHistoryCurrentPage: 1,
  materialVariantHistoryTotalItems: 0,
  materialVariantHistoryTotalPages: 0,
  materialVariantHistoryPDF: [],
  stockMaterialVariantReporting: [],
  materialVariantLocationNameLike: [],
  materialLocationMaterialNameLike: [],
  materialColors: [],
  materialVariantColorNameLike: [],
  colorDataPagination: [],
  colorLikeName: [],
  filterDescription: [],
  filterDescriptions: [],
  descriptionFilterOverview: [],
  materialNameFiltering: [],
  filterVariantsByCodeLike: [],
  filterByCodeTotalItems: [],
}

const getters = {
  getMaterialVariant: (state) => {
    return state.materialvariants;
  },
  getMatVarCurrentPage: (state) => {
    return state.MatVarCurrentPage;
  },
  getMaterialVariantColorNameLike: (state) => {
    return state.materialVariantColorNameLike;
  },
  getTotalItemsPages: (state) => {
    return state.totalItemsPages
  },
  getMatVarTotalItems: (state) => {
    return state.MatVarTotalItems;
  },
  getFilteredMV: (state) => {
    return state.filteredMV;
  },
  getColorsData: (state) => {
    return state.colorsData;
  },
  getTotalItemsForColors: (state) => {
    return state.totalItemsColors;
  },
  getCurrentPageColors: (state) => {
    return state.currentPageColors;
  },
  getCurrentPageForInvMV: (state) => {
    return state.currentPage;
  },
  getTotalItemsForInvMV: (state) => {
    return state.totalItems;
  },
  getInventoryMaterialVariants: (state) => {
    return state.inventoryMaterialVariants;
  },
  getInventoryMaterialVariantsId: (state) => {
    return state.inventoryMaterialVariantsId;
  },
  getFilteredMVForSearch: (state) => {
    return state.filteredForSearch;
  },
  getMaterialVariantNames: (state) => {
    return state.materialVariantNames;
  },
  getMaterialColors: (state) => {
    return state.materialColors;
  },
  getMaterialVariantHistory: (state) => {
    return state.materialVariantHistory;
  },
  getMaterialVariantHistoryCurrentPage: (state) => {
    return state.materialVariantHistoryCurrentPage;
  },
  getMaterialVariantHistoryTotalItems: (state) => {
    return state.materialVariantHistoryTotalItems;
  },
  getMaterialVariantHistoryTotalPages: (state) => {
    return state.materialVariantHistoryTotalPages
  },
  getMaterialVariantHistoryPDF: (state) => {
    return state.materialVariantHistoryPDF
  },
  getColorNames: (state) => {
    return state.colorNames;
  },
  getStockMaterialVariantReporting: (state) => {
    return state.stockMaterialVariantReporting
  },
  getMaterialVariantLocationNameLike: (state) => {
    return state.materialVariantLocationNameLike
  },
  getMaterialLocationMaterialNameLike: (state) => {
    return state.materialLocationMaterialNameLike
  },
  getMaterialVariantPDF: (state) => {
    return state.materialvariantsPDF;
  },
  getMaterialVariantPDFQRCode: (state) => {
    return state.materialvariantsPDFQRCode;
  },
  getColorDataPagination: (state) => {
    return state.colorDataPagination
  },
  getColorLikeName: (state) => {
    return state.colorLikeName
  },
  getFilterDescription: (state) => {
    return state.filterDescription
  },
  getFilterDescriptions: (state) => {
    return state.filterDescriptions
  },
  getDescriptionFilterOverview: (state) => {
    return state.descriptionFilterOverview
  },
  getMaterialNameFiltering: (state) => {
    return state.materialNameFiltering
  },
  getFilterVariantsByCodeLike: (state) => {
    return state.filterVariantsByCodeLike
  },
  getFilterByCodeTotalItems: (state) => {
    return state.filterByCodeTotalItems
  },
};

const mutations = {
  SET_STATUS(state, payload) {
    state.status = payload;
  },
  SET_MATERIALVARIANT(state, latest) {
    state.materialvariants = latest;
  },
  SET_MATERIAL_VARIANT_COLORNAME_LIKE(state, latest) {
    state.materialVariantColorNameLike = latest
  },
  SET_TOTAL_ITEMS_PAGES(state, latest) {
    state.totalItemsPages = latest
  },
  SET_MATERIALVARIANT_CURRENTPAGE(state, latest) {
    state.MatVarCurrentPage = latest;
  },
  SET_MATERIALVARIANT_TOTAL_ITEMS(state, latest) {
    state.MatVarTotalItems = latest;
  },
  SET_FILTERED_MV(state, latest) {
    state.filteredMV = latest;
  },
  SET_INVENTORY_MATERIAL_VARIANTS(state, latest) {
    state.inventoryMaterialVariants = latest;
  },
  SET_INVENTORY_MATERIAL_VARIANTSID(state, latest) {
    state.inventoryMaterialVariantsId = latest;
  },
  SET_COLOR_DATA(state, latest) {
    state.colorsData = latest;
  },
  SET_MATERIAL_COLORS(state, latest) {
    state.materialColors = latest;
  },
  SET_CURRENT_PAGE(state, latest) {
    state.currentPage = latest;
  },
  SET_CURRENT_PAGE_COLORS(state, latest) {
    state.currentPageColors = latest;
  },
  SET_TOTAL_ITEMS_COLORS(state, latest) {
    state.totalItemsColors = latest;
  },
  SET_TOTAL_ITEMS(state, latest) {
    state.totalItems = latest;
  },
  SET_FILTERED_MV_BY_SEARCH(state, latest) {
    state.filteredForSearch = latest
  },
  SET_MATERIAL_VARIANT_NAMES(state, latest) {
    state.materialVariantNames = latest
  },
  SET_MATERIAL_VARIANT_HISTORY(state, latest) {
    state.materialVariantHistory = latest
  },
  SET_CURRENT_PAGE_MATERIAL_VARIANT_HISTORY(state, latest) {
    state.materialVariantHistoryCurrentPage = latest
  },
  SET_TOTAL_ITEMS_MATERIAL_VARIANT_HISTORY(state, latest) {
    state.materialVariantHistoryTotalItems = latest
  },
  SET_TOTAL_PAGES_MATERIAL_VARIANT_HISTORY(state, latest) {
    state.materialVariantHistoryTotalPages = latest
  },
  SET_MATERIAL_VARIANT_HISTORY_PDF(state, latest) {
    state.materialVariantHistoryPDF = latest
  },
  SET_COLOR_NAMES(state, latest) {
    state.colorNames = latest
  },
  SET_STOCK_MATERIALVARIANT_REPORTING(state, latest) {
    state.stockMaterialVariantReporting = latest
  },
  SET_MATERIAL_VARIANT_LOCATION_NAMELIKE(state, latest) {
    state.materialVariantLocationNameLike = latest
  },
  SET_MATERIAL_LOCATION_MATERIALNAME_NAME_LIKE(state, latest) {
    state.materialLocationMaterialNameLike = latest
  },
  SET_MATERIAL_VARIANT_PDF(state, latest) {
    state.materialvariantsPDF = latest
  },
  SET_MATERIAL_VARIANT_PDF_QRCODE(state, latest) {
    state.materialvariantsPDFQRCode = latest
  },
  SET_COLOR_DATA_PAGINATION(state, latest) {
    state.colorDataPagination = latest
  },
  SET_COLOR_LIKE_NAME(state, latest) {
    state.colorLikeName = latest
  },
  SET_FILTER_BY_DESCRIPTION(state, latest) {
    state.filterDescription = latest
  },
  SET_FILTER_BY_DESCRIPTIONS(state, latest) {
    state.filterDescriptions = latest
  },
  SET_DESCRIPTION_FILTER_OVERVIEW(state, latest) {
    state.descriptionFilterOverview = latest
  },
  SET_MATERIAL_NAME_FILTERING(state, latest) {
    state.materialNameFiltering = latest
  },
  SET_FILTER_VARIANT_BY_CODE(state, latest) {
    state.filterVariantsByCodeLike = latest
  },
  SET_FILTER_BY_CODE_TOTALITEMS(state, latest) {
    state.filterByCodeTotalItems = latest
  },
};
const actions = {
  async addMaterialVariant({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    // const objToJson = JSON.stringify(object);
    try {
      const response = await axios.post(`${domain}/addMaterialVariant`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback(response.data);
      Swal.fire({
        icon: 'success',
        title: 'Material variant added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async materialColorNameLike({ commit }, colorName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/colorDataLikeColorName`, {
        params: {
          colorName,
        },
      });
      commit('SET_MATERIAL_COLORS', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async variantsByDescription({ commit }, { descriptionName, materialId, measurementType }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_descriptions_by_name_and_materialId`, {
        params: {
          descriptionName,
          materialId,
          measurementType,
        },
      });
      commit('SET_FILTER_BY_DESCRIPTION', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterByDescription({ commit }, { descriptionName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_descriptions_by_name`, {
        params: {
          descriptionName,
        },
      });
      commit('SET_FILTER_BY_DESCRIPTIONS', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMaterialVariant({ commit }, {
    id, materialVariantId, colorId, pageNumber, pageSize, successCallback,

  }) {
    const response = await axios.get(`${domain}/getMaterialVariantsByMaterialId?materialId=${id}`, {
      params: {
        materialVariantId,
        colorId,
        pageNumber,
        pageSize,
      },
    });
    commit('SET_MATERIALVARIANT', response.data.items);
    commit('SET_MATERIALVARIANT_CURRENTPAGE', response.data.currentPage);
    commit('SET_MATERIALVARIANT_TOTAL_ITEMS', response.data.totalItems);

    successCallback()
  },
  async loadMaterialVariants({ commit }, {
    materialId, materialVariantId, colorId, pageNumber, pageSize, successCallback,

  }) {
    const response = await axios.get(`${domain}/getMaterialVariantsByMaterialId`, {
      params: {
        materialId,
        materialVariantId,
        colorId,
        pageNumber,
        pageSize,
      },
    });
    commit('SET_MATERIALVARIANT', response.data.items);
    commit('SET_MATERIALVARIANT_CURRENTPAGE', response.data.currentPage);
    commit('SET_MATERIALVARIANT_TOTAL_ITEMS', response.data.totalItems);

    successCallback()
  },
  async resetMaterialVariant({ commit }) {
    commit('SET_MATERIALVARIANT', []);
    commit('SET_MATERIALVARIANT_TOTAL_ITEMS', 0);
  },
  async filterVariantByColor({ commit }, {
    name, measurementType, pageNumber, pageSize, successCallback,

  }) {
    const response = await axios.get(`${domain}/getMaterialVaraintsToAddMainDressCategoryColorNameLike`, {
      params: {
        name,
        measurementType,
        pageNumber,
        pageSize,
      },
    });
    commit('SET_MATERIAL_VARIANT_COLORNAME_LIKE', response.data.items);
    commit('SET_TOTAL_ITEMS_PAGES', response.data.totalPages)
    successCallback()
  },
  async filterVariantByCode({ commit }, {
    code, measurementType, pageNumber, pageSize, successCallback,

  }) {
    const response = await axios.get(`${domain}/variants_by_code_like`, {
      params: {
        code,
        measurementType,
        pageNumber,
        pageSize,
      },
    });
    commit('SET_FILTER_VARIANT_BY_CODE', response.data.items);
    commit('SET_FILTER_BY_CODE_TOTALITEMS', response.data.totalPages)
    successCallback()
  },
  async resetLoadMaterialVariant({ commit }) {
    commit('SET_MATERIALVARIANT', []);
  },
  async editMaterialVariantReq({ commit }, { materialVariantId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editMaterialVariantById/${materialVariantId} `, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Material variant by id edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async removeMaterialVariant({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/deleteMaterialvariant/id/${id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Material variant deleted succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addStock({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/addStockToMaterialVariant`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Stock to material variant added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid stock data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async subStock({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/substractStockToMaterialVariant`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Stock substracted succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid stock data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadColors({ commit }, { colorName, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const res = await axios.get(`${domain}/colodDataPagination`, {
        params: {
          colorName,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_COLOR_DATA', res.data.items);
      commit('SET_CURRENT_PAGE_COLORS', res.data.currentPage);
      commit('SET_TOTAL_ITEMS_COLORS', res.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async colorsPagination({ commit }, { colorId, pageNumber, pageSize }) {
    commit('SET_IS_LOADING', false);
    try {
      const res = await axios.get(`${domain}/colors_pagination`, {
        params: {
          colorId,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_COLOR_DATA_PAGINATION', res.data.items);
      commit('SET_CURRENT_PAGE_COLORS', res.data.currentPage);
      commit('SET_TOTAL_ITEMS_COLORS', res.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async addColor({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addColodData`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Color Data added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid color data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async colorNameLike({ commit }, colorName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/colorDataColorNameLike?colorName=${colorName}`);
      commit('SET_COLOR_NAMES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async colorDataLike({ commit }, colorName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/colorDataLikeColorName?colorName=${colorName}`);
      commit('SET_COLOR_LIKE_NAME', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async materialVariantNameLike({ commit }, { materialName, materialId, measurementType }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialNamesByNameLikeAndMaterialId`, {
        params: {
          materialName,
          materialId,
          measurementType,
        },
      });
      commit('SET_MATERIAL_VARIANT_NAMES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async materialVariantHistoryTrackPagination({ commit }, {
    month, year, materialVariantId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialVariantDeliveryTrackPagination`, {
        params: {
          materialVariantId,
          pageNumber,
          pageSize,
          year,
          month,
        },
      });
      commit('SET_MATERIAL_VARIANT_HISTORY', response.data.items);
      commit('SET_CURRENT_PAGE_MATERIAL_VARIANT_HISTORY', response.data.currentPage);
      commit('SET_TOTAL_ITEMS_MATERIAL_VARIANT_HISTORY', response.data.totalItems);
      commit('SET_TOTAL_PAGES_MATERIAL_VARIANT_HISTORY', response.data.totalPages);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async materialVariantHistoryTrackPaginationPDF({ commit }, {
    month, year, materialVariantId, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialVariantDeliveryTrackPagination`, {
        params: {
          materialVariantId,
          pageNumber,
          pageSize,
          year,
          month,
        },
      });
      commit('SET_MATERIAL_VARIANT_HISTORY_PDF', response.data.items);
      commit('SET_CURRENT_PAGE_MATERIAL_VARIANT_HISTORY', response.data.currentPage);
      commit('SET_TOTAL_ITEMS_MATERIAL_VARIANT_HISTORY', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetMaterialVariantNames({ commit }) {
    commit('SET_MATERIAL_VARIANT_NAMES', []);
  },
  resetColorNames({ commit }) {
    commit('SET_COLOR_NAMES', []);
  },
  async editColor({ commit }, { object, colorId, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editColorDataById?id=${colorId}`, object);
      commit('SET_STATUS', true);
      successCallback()
      Swal.fire({
        icon: 'success',
        title: 'Color data by Id edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid color data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },

  async loadMaterialVaraintsCategoryNameLike({ commit }, {
    name, measurementType, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getMaterialVaraintsToAddMainDressCategoryNameLike`, {
        params: {
          name,
          measurementType,
          pageNumber,
          pageSize,
        },
      })
      commit('SET_FILTERED_MV', response.data)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  testResetMV({ commit }) {
    commit('SET_FILTERED_MV', [])
  },
  resetAllFiltered({ commit }) {
    commit('SET_MATERIAL_VARIANT_COLORNAME_LIKE', [])
  },
  async loadInventoryMaterialVariants({ commit }, {
    totalStock, pageNumber, pageSize, materialVariantName, description, materialId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inventoryMaterialVariantReportingPagination`, {
        params: {
          totalStock,
          pageNumber,
          pageSize,
          materialVariantName,
          description,
          materialId,
        },
      });
      commit('SET_INVENTORY_MATERIAL_VARIANTS', response.data.items);
      commit('SET_CURRENT_PAGE', response.data.currentPage);
      commit('SET_TOTAL_ITEMS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadInventoryMaterialVariantsId({ commit }, materialVariantVariantId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inventoryMaterialVariantReportingByMaterialVariantId`, {
        params: {
          materialVariantVariantId,
        },
      });
      commit('SET_INVENTORY_MATERIAL_VARIANTSID', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMaterialVariantsPDF({ commit }, {
    totalStock, pageNumber, pageSize, materialVariantName,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inventoryMaterialVariantReportingPagination`, {
        params: {
          totalStock,
          pageNumber,
          pageSize,
          materialVariantName,
        },
      });
      commit('SET_MATERIAL_VARIANT_PDF', response.data);
      commit('SET_CURRENT_PAGE', response.data.currentPage);
      commit('SET_TOTAL_ITEMS', response.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMaterialVariantsPDFQRCode({ commit }, materialVariantVariantId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/inventoryMaterialVariantReportingByMaterialVariantId`, {
        params: {
          materialVariantVariantId,
        },
      });
      commit('SET_MATERIAL_VARIANT_PDF_QRCODE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async filterMaterialVariantsByName({ commit }, materialVariantName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialVariantNamesByNameLike?materialVariantName=${materialVariantName}`);
      commit('SET_FILTERED_MV_BY_SEARCH', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async descriptionFilterOverview({ commit }, { description }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/filter_descriptions_by_value_like?description=${description}`);
      commit('SET_DESCRIPTION_FILTER_OVERVIEW', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadMaterialsNames({ commit }, { materialName }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialNames_dropdown_selection`, {
        params: {
          materialName,
        },
      });
      commit('SET_MATERIAL_NAME_FILTERING', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  resetFilteredMVBySearch({ commit }) {
    commit('SET_FILTERED_MV_BY_SEARCH', []);
  },
  async reporting_less_stockMaterialVariants({ commit }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/reporting_less_stockMaterialVariants`);
      commit('SET_STOCK_MATERIALVARIANT_REPORTING', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async materialVariant_location_nameLike({ commit }, materialVariantName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/materialVariant_location_nameLike?materialVariantName=${materialVariantName}`);
      commit('SET_MATERIAL_VARIANT_LOCATION_NAMELIKE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async material_location_materialNameLike({ commit }, materialName) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/material_location_materialNameLike?materialName=${materialName}`);
      commit('SET_MATERIAL_LOCATION_MATERIALNAME_NAME_LIKE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async editMaterialVariantById_new({ commit }, { materialVariantId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editMaterialVariantById_new?materialVariantId=${materialVariantId}`, object);
      commit('SET_STATUS', true);
      successCallback()
      Swal.fire({
        icon: 'success',
        title: 'Color data by Id edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid color data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
}
