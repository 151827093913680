<template>
  <div
    class="notification-dropdown"
  >
    <button
      class="notification-button"
      @click="toggleDropdown()"
    >
      <b-icon
        style="height: 40px; width: 22px; color: #3A3A3A"
        icon="cart-check-fill"
        aria-hidden="true"
        class="white-icon"
      />
      <span
        v-if="cartItemCount > 0"
        class="cart-counter"
      >{{ cartItemCount }}</span>
      <!-- || getNotificationCountFromBack > 0 -->
    </button>
    <div
      v-show="showDropdown"
      ref="dropdown"
      v-click-outside="handleClickOutside"
      class="dropdown-content"
      @mouseleave="closeCart"
    >
      <div style="display: flex; justify-content: space-between; flex-direction: column;">
        <h1 class="notification-title">
          {{ $t("Pending") }}
        </h1>
      </div>
      <div
        style="text-decoration: none; color: black; z-index: 10"
      >
        <ul class="notification-list">
          <li
            v-for="item in modifiedItemsCart"
            :key="item.id"
            class="notification-item"
            style="width: 100%"
          >
            <div class="notification-icon">
              <b-icon
                v-b-tooltip.hover.bottom
                :title="`\nOrdered Amount: ${item.amount} \nColor: ${item.color} \nMain Supplier: ${item.mainSupplierName} \n${item.secondSupplierName ? `Second Supplier: ${item.secondSupplierName}`: '' }`"
                icon="info-circle"
              />
            </div>
            <div
              v-show="showDropdown"
              ref="dropdown"
              class="notification-message router-link-wrapper"
              style="width: 100%; z-index: 100"
            >
              <div
                class="notification-text"
                @click="
                  markOne();
                  notificationIDdd(item.notificationId);
                "
              >
                <div style="display: flex; gap: 5%; align-items: center; width: 100%;">
                  <img
                    v-b-tooltip="$t('ClickToViewPhoto')"
                    style="height: 60px; border-radius: 3px; width: 24%"
                    :src="item.fileUrl"
                    alt=""
                    @click.stop="getAllImages(item)"
                  >
                  <div style="width: 100%;">
                    <span style="padding-bottom: 15px;">{{ $t('MaterialName') }}</span>
                    <span style="font-weight: 700;">{{ item.materialVariantName }}</span>
                  </div>
                </div>
                <div class="price">
                  <span style="padding-bottom: 15px;font-weight: 700;">{{ item.orderedPrice }}€</span>
                  <button>{{ $t('OnHold') }}</button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <vue-easy-lightbox
      :esc-disabled="false"
      :visible="image.visible"
      :imgs="image.imgs"
      :index="image.index"
      @hide="handleHide"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import connection from '@/microsoft-signalR/signalR';
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      showDropdown: false,
      // notificationCount: 2,
      pageNumber: 1,
      pageSize: 15,
      dropdownOpen: false,
      notiID: null,
      image: {
        zoom: 1,
        imgs: [], // Img Url , string or Array of string
        visible: false,
        index: 0, //
      },
    };
  },
  computed: {
    ...mapGetters([
      'getNotifications',
      'getNotificationCount',
      'getTotalItemsForUsers',
      'getNotificationCountFromBack',
      'notificationCount',
      'getItemsCart',
    ]),
    modifiedItemsCart() {
      const descSortedItems = this.getItemsCart.slice().sort((a, b) => { return b - a });
      return descSortedItems;
    },
    getTotalNotificationCount() {
      if (this.getNotificationCount === 0) {
        return this.getNotificationCountFromBack;
      }
      if (this.getNotificationCountFromBack === 0) {
        return this.getNotificationCount;
      }
      return this.getNotificationCount;
    },
    updatedNotificationCount() {
      return this.notificationCount != null
        ? this.notificationCount
        : this.getNotificationCountFromBack;
    },
    cartItemCount() {
      return this.getItemsCart.length;
    },
  },
  watch: {
    getTotalNotificationCount() {
      if (this.getNotificationCount === 0) {
        return this.getNotificationCountFromBack;
      }
      if (this.getNotificationCountFromBack === 0) {
        return this.getNotificationCount;
      }
      return this.getNotificationCount + this.getNotificationCountFromBack;
    },
    pageNumber(value) {
      this.loadNotifications({
        pageNumber: value,
        pageSize: this.pageSize,
      });
    },
    getNotificationCountFromBack() {
      this.notifications_number();
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    this.notifications_number();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
  // eslint-disable-next-line vue/order-in-components
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line func-names
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  methods: {
    ...mapActions([
      'loadNotifications',
      'resetNotificationCount',
      'mark_all_read',
      'mark_read',
      'notifications_number',
      'resetNotificationCountFromBack',
      'loadCartItems',
    ]),
    closeCart() {
      this.showDropdown = false
    },
    toggleDropdown() {
      // this.resetNotificationCount();
      // this.resetNotificationCountFromBack();
      this.showDropdown = !this.showDropdown;
      if (this.showDropdown) {
        this.loadCartItems({ state: 'Pending' });
      }
    },
    notificationIDdd(notificationId) {
      connection
        .invoke('MarkReadNotificationAlert', notificationId)

        .then((value) => {
          console.log(value);
        })

        .catch((error) => {
          console.error(error);
        });
    },
    markAll() {
      this.mark_all_read();
      this.loadNotifications({
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
      // eslint-disable-next-line no-unused-expressions
      this.getTotalNotificationCount == 0;
    },
    decreaseNotificationCount() {
      this.showDropdown = false;
      if (this.getNotificationCount > 0) {
        this.getNotificationCount--;
      } else if (this.getNotificationCountFromBack > 0) {
        this.getNotificationCountFromBack--;
      }
    },
    markOne() {
      // this.loadNotifications({
      //   pageNumber: this.pageNumber,
      //   pageSize: this.pageSize,
      // });
      this.$router.push('/cart')
      this.showDropdown = !this.showDropdown;
    },
    dateModified(value) {
      return moment(value).format('DD-MM-YYYY HH:mm');
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    async getAllImages(item) {
      // Set the array to an empty state at the beginning
      this.image.imgs = [];
      this.image.imgs.push({
        src: item.fileUrl,
      })

      // Continue with your other logic
      this.show();
    },
    show() {
      this.image.visible = true
    },
    handleHide() {
      this.image.visible = false
      this.image.imgs.length = []
    },
  },
};
</script>

  <style scoped>
  .cart-counter {
    position: absolute;
    top: 0px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0px 5px;
    font-size: 12px;
  }
  .dropdown {
    position: absolute;
    top: 100%;
    right: 21%;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    z-index: 999;
    /* Add any other desired styles */
  }

  .pagination-container .page-item:not(:first-child):not(:last-child) {
    display: none;
  }

  .notification-dropdown {
    /* position: relative;
    margin-left: 60px; */
    z-index: 9999 !important;
  }

  .notification-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .dropdown-content {
    position: absolute;
    top: 117%;
    right: 0;
    width: 400px;
    max-height: 400px;
    overflow-y: auto;
    background-color: #ffffff;
    border: 1px solid #DCDFE4;
    border-radius: 4px;
    padding: 16px;
  }

  .notification-title {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: 400;
    border-bottom: 1px solid lightgray;
    padding-bottom: 2%;
  }

  .notification-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
  }

  .notification-item {
    display: flex;
    align-items: center;
    padding: 4px 0;
    border-bottom: 1px solid #eee;
  }

  .notification-icon {
    margin-right: 8px;
    color: #777;
  }

  .notification-text {
    margin: 0;
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: inherit;cursor: pointer;
    height: 70px;
    align-items: center;
  }

  .notification-text span {
    display: flex;
    align-items: center;
    font-size: 15px;
  }

  .notification-time {
    margin: 0;
    color: #999;
    font-size: 12px;
  }

  .see-all-link {
    display: block;
    text-align: left;
    color: #007bff;
    margin-top: 16px;
  }

  .notification-counter {
    position: absolute;
    top: 0;
    right: 4.2%;
    background-color: #ff5252;
    color: #fff;
    font-size: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .price {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }

  .price button {
    width: 75px;
    height: 26px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    background: #e5e5e5;
  }

  .router-link-wrapper {
    /* Add your custom styles here */
    /* For example, to remove underline and change color: */
    text-decoration: none;
    color: inherit;
  }
  </style>
