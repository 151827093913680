import axios from 'axios'
import Swal from 'sweetalert2';
import { api } from '../../domainConfig';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const domain = api.masterDataDomain;

const state = {
  materialVariantCart: [],
  itemsCart: [],
  orderedMaterials: [],
  orderItems: [],
  orderItemsCartId: [],
  orderHistory: [],
  images: null,
  currentPage: 1,
  isInCart: null,
  totalItems: 0,
  totalItemsExpenses: 0,
  totalPagesExpenses: 1,
  invoiceNumberLike: [],
  historyMaterialVariant: [],
  historyMaterialVariantExpenses: [],
  purchaseByCart: [],
}

const getters = {
  getImagesVariantOrder: (state) => {
    return state.images;
  },
  getPruchaseByCart: (state) => {
    return state.purchaseByCart
  },
  getMaterialVariantCart: (state) => {
    return state.materialVariantCart
  },
  getItemsCart: (state) => {
    state.itemsCart.reverse()
    return state.itemsCart
  },
  getHistoryMaterialVariant: (state) => {
    return state.historyMaterialVariant
  },
  getHistoryMaterialVariantExpenses: (state) => {
    return state.historyMaterialVariantExpenses
  },
  getIsInCart: (state) => {
    return state.isInCart
  },
  getOrderItems: (state) => {
    return state.orderItems
  },
  getHistoryTracking: (state) => {
    return state.orderHistory
  },
  getOrderedMaterials: (state) => {
    return state.orderedMaterials;
  },
  getInvoiceNumber: (state) => {
    return state.invoiceNumberLike;
  },
  getCurrentPageForMaterialOrders: (state) => {
    return state.currentPage;
  },
  getTotalItemsForMaterialOrders: (state) => {
    return state.totalItems;
  },
  getTotalItemsExpenses: (state) => {
    return state.totalItemsExpenses
  },
  getTotalPagesExpenses: (state) => {
    return state.totalPagesExpenses
  },
  getOrderItemsCartId: (state) => {
    return state.orderItemsCartId
  },
};

const mutations = {
  SET_MATERIAL_VARIANT_CART(state, latest) {
    state.materialVariantCart = latest
  },
  SET_ITEMS_CART(state, latest) {
    state.itemsCart = latest
  },
  SET_PURCHASE_BY_CART(state, latest) {
    state.purchaseByCart = latest
  },
  SET_HISTORY_MATERIAL_VARIANT(state, latest) {
    state.historyMaterialVariant = latest
  },
  SET_HISTORY_MATERIAL_VARIANT_EXPENSES(state, latest) {
    state.historyMaterialVariantExpenses = latest
  },
  SET_ORDER_ITEMS(state, latest) {
    state.orderItems = latest
  },
  SET_IS_IN_CART(state, latest) {
    state.isInCart = latest
  },
  SET_ORDER_MATERIAL_HISTORY(state, latest) {
    state.orderHistory = latest
  },
  SET_IMAGES(state, latest) {
    state.images = latest;
  },
  SET_ITEMS_ORDERED(state, latest) {
    state.orderedMaterials = latest
  },
  SET_INVOICE_NUMBER_LIKE(state, latest) {
    state.invoiceNumberLike = latest
  },
  SET_CURRENTPAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_TOTALITEMS_ORDERED_MATERIALS(state, latest) {
    state.totalItems = latest;
  },
  SET_TOTAL_ITEMS_EXPENSES(state, latest) {
    state.totalItemsExpenses = latest
  },
  SET_TOTAL_PAGES_EXPENSES(state, latest) {
    state.totalPagesExpenses = latest
  },
  SET_ORDER_MATERIAL_BY_CARDID(state, latest) {
    state.orderItemsCartId = latest;
  },
};
const actions = {
  async deleteFromCart({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/deleteVariantItemCartById?variantItemCartId=${id}`);
      commit('SET_STATUS', true);
      Swal.fire({
        icon: 'success',
        title: 'Item removed from the cart succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      successCallback();
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid files data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
  },
  async deleteFileFromMaterialOrder({ commit }, { id, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.delete(`${domain}/deleteOrderaCartFileByFileId?fileDataId=${id}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Photo deleted from the order succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid files data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
  },
  async changeStateOfMaterialOrder({ commit }, {
    object, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/changeOrderCartState`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Status of the order succesfully changed',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async changeSupplier({ commit }, {
    variantItemCartId, newOrderedSupplierId, successCallback,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/changeOrderedSupplier?variantItemCartId=${variantItemCartId}&newOrderedSupplierId=${newOrderedSupplierId}`);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Supplier changed succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async editCartMaterialVariant({ commit }, { id, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/editVariantItemCart?id=${id}`, object);
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Material variant edited succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async receiveMaterialVariants({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.put(`${domain}/receive_material_variants`, object);
      commit('SET_STATUS', true);
      successCallback();
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addMaterialVariantToCart({ commit }, { object, successCallback }) {
    commit('SET_IS_LOADING', false);
    // const objToJson = JSON.stringify(object);
    try {
      await axios.post(`${domain}/addItemToCart`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Material variant added succesfully to cart',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addFileMaterialOrder({ commit }, { orderCartId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    try {
      await axios.post(`${domain}/addFileToOrderCart?orderCartId=${orderCartId}`, object, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'File added succesfully',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async addCartOrder({ commit }, { supplierId, object, successCallback }) {
    commit('SET_IS_LOADING', false);
    // const objToJson = JSON.stringify(object);
    try {
      await axios.post(`${domain}/addOrderCart`, object, {
        params: {
          supplierId,
        },
      }, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit('SET_STATUS', true);
      successCallback();
      Swal.fire({
        icon: 'success',
        title: 'Order succesfully created',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
      });
      commit('SET_IS_LOADING', true);
    } catch (error) {
      // Handle error response
      // Show error message using SweetAlert if needed
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid material variant data. Please try again.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
      } else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: error.response.data.Message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
        });
        commit('SET_IS_LOADING', true);
      }
    }
    commit('SET_IS_LOADING', true);
  },
  async loadCartItems({ commit }, { state }) {
    commit('SET_IS_LOADING', false);
    try {
      const res = await axios.get(`${domain}/variantItem_cart`, {
        params: {
          state,
        },
      });
      commit('SET_ITEMS_CART', res.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadPurchaseByCart({ commit }, { orderCartId }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/purchase_direct_by_order_cartId`, {
        params: {
          orderCartId,
        },
      });
      commit('SET_PURCHASE_BY_CART', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadHistoryMaterialVariant({ commit }, { materialVariantId, startDate, endDate }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/history_materialVariant`, {
        params: {
          materialVariantId,
          startDate,
          endDate,
        },
      });
      commit('SET_HISTORY_MATERIAL_VARIANT', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetHistoryMaterialVariant({ commit }) {
    commit('SET_HISTORY_MATERIAL_VARIANT', []);
  },
  async loadHistoryMaterialVariantExpenses({ commit }, {
    materialVariantId, startDate, endDate, pageNumber, pageSize,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/history_materialVariant_expenses`, {
        params: {
          materialVariantId,
          startDate,
          endDate,
          pageNumber,
          pageSize,
        },
      });
      commit('SET_HISTORY_MATERIAL_VARIANT_EXPENSES', response.data.items);
      commit('SET_TOTAL_ITEMS_EXPENSES', response.data.totalItems)
      commit('SET_TOTAL_PAGES_EXPENSES', response.data.totalPages)
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetHistoryMaterialVariantExpenses({ commit }) {
    commit('SET_HISTORY_MATERIAL_VARIANT_EXPENSES', []);
    commit('SET_TOTAL_ITEMS_EXPENSES', 0)
    commit('SET_TOTAL_PAGES_EXPENSES', 1)
  },
  async loadOrderItems({ commit }, { orderCartId }) {
    commit('SET_IS_LOADING', false);
    try {
      const res = await axios.get(`${domain}/variantItemCartByOrderCartId`, {
        params: {
          orderCartId,
        },
      });
      commit('SET_ORDER_ITEMS', res.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async clearLoadOrderItems({ commit }) {
    commit('SET_ORDER_ITEMS', []);
  },
  async loadOrderItemsByCardId({ commit }, { orderCartId }) {
    commit('SET_IS_LOADING', false);
    try {
      const res = await axios.get(`${domain}/orderer_material_variants_by_OrderCartId`, {
        params: {
          orderCartId,
        },
      });
      commit('SET_ORDER_MATERIAL_BY_CARDID', res.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async isAlreadyInCart({ commit }, { materialVariantId }) {
    commit('SET_IS_LOADING', false);
    try {
      const res = await axios.get(`${domain}/already_variantItemInCart`, {
        params: {
          materialVariantId,
        },
      });
      commit('SET_IS_IN_CART', res.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async getImagesOrderItemId({ commit }, orderCartId) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/getFileByOrderCartId?orderCartId=${orderCartId}`);
      commit('SET_IMAGES', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadTrackingMaterialOrder({ commit }, { orderCartId }) {
    commit('SET_IS_LOADING', false);
    try {
      const res = await axios.get(`${domain}/getOrderCartTrackingByOrderCartId`, {
        params: {
          orderCartId,
        },
      });
      commit('SET_ORDER_MATERIAL_HISTORY', res.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadOrderedMaterials({ commit }, {
    orderCartType, state, month, year, pageNumber, pageSize, orderCartId,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const res = await axios.get(`${domain}/order_cart_pagination_by_type`, {
        params: {
          orderCartType,
          state,
          month,
          year,
          pageNumber,
          pageSize,
          orderCartId,
        },
      });
      commit('SET_ITEMS_ORDERED', res.data.items);
      commit('SET_CURRENTPAGE', res.data.currentPage);
      commit('SET_TOTALITEMS_ORDERED_MATERIALS', res.data.totalItems);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async loadInvoiceNumberLike({ commit }, {
    invoiceNumber,
  }) {
    commit('SET_IS_LOADING', false);
    try {
      const response = await axios.get(`${domain}/invoice_number_like`, {
        params: {
          invoiceNumber,
        },
      });
      commit('SET_INVOICE_NUMBER_LIKE', response.data);
    } catch (error) {
      commit('SET_IS_LOADING', true)
    }
    commit('SET_IS_LOADING', true);
  },
  async resetLoadInvoiceNumberLike({ commit }) {
    commit('SET_INVOICE_NUMBER_LIKE', []);
  },
};


export default {
  state,
  getters,
  mutations,
  actions,
}
