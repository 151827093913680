const api = {};
const AWSurl = {};
const client = {};

if (process.env.VUE_APP_MasterDataDomain == null) {
  api.masterDataDomain = 'https://railwdevmasterdata.digitwebsite.ch/api/v1/02EDA81C-41DF-4522-AC4D-08DC31F47A6C';
  api.stockManagementDomain = 'https://railwdevordermanagement.digitwebsite.ch/api/v1';
  api.userManagementDomain = 'https://railwdevusermanagement.digitwebsite.ch/api/v1/02EDA81C-41DF-4522-AC4D-08DC31F47A6C';
  api.clientMasterDataDomain = 'https://railwdevclientmasterdata.digitwebsite.ch/api/v1/02EDA81C-41DF-4522-AC4D-08DC31F47A6C';
  api.vacationManagementDomain = 'https://railwdevvacationmanagement.digitwebsite.ch/api/v1/02EDA81C-41DF-4522-AC4D-08DC31F47A6C';
  api.stockManagementDomainNotification = 'https://railwdevordermanagement.digitwebsite.ch';
  api.financeDomain = 'https://railwdevaccountingmanagement.digitwebsite.ch/api/v1/02EDA81C-41DF-4522-AC4D-08DC31F47A6C';

  // tenant Ids
  // ClientName: ValdrinSahiti
  // TenantId: 675876C0-FB64-4E91-AC4F-08DC31F47A6C

  // ClientName: WaloneFashion
  // TenantId: 7687CC0B-B270-4754-AC4E-08DC31F47A6C

  // ClientName: DsLogistik
  // TenantId: 4F5EF963-2335-43DC-3ECC-08DC3C4B89FA

  // Development:
  // TenantId: 02EDA81C-41DF-4522-AC4D-08DC31F47A6C

  // ClientName: FjollaNila
  // TenantId: D0E8DC87-A2F6-4295-B795-E94AF70DA2FC

  // ClientName: MinnaFashion

  // ClientName: NotaBrillant

  AWSurl.bucketName = 'spim-file-development';
  AWSurl.accessKeyId = 'AKIAZN2PMAGNIXQ74O4R';
  AWSurl.secretAccessKey = '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';
  AWSurl.region = 'eu-central-1';


  client.clientName = 'WaloneFashion';
  client.stateType = 'High';
  client.clientLogo = 'https://spim-file.s3.amazonaws.com/CRM_ClientsPhoto/DrenushaLogoWhite.png'
  // client.clientLogo = 'https://spim-file.s3.amazonaws.com/CRM_ClientsPhoto/ValdrinSahitiWhiteLogo.png'
  // client.clientLogo = 'https://spim-file.s3.amazonaws.com/CRM_ClientsPhoto/FjollaNilaWhiteLogo.png'

  // INFO
  /*
    stateType = 'Low' is for clients [DrenushaXharra]
    stateType = 'Medium' is for clients []
    stateType = 'High' is for clients [FjollaNila, ValdrinSahiti, WaloneFashionGroup, AnariAtelier]
    Also dashboards are seperated based on the stateType not clientName
  */
} else {
  api.masterDataDomain = process.env.VUE_APP_MasterDataDomain;
  api.stockManagementDomain = process.env.VUE_APP_StockManagementDomain;
  api.userManagementDomain = process.env.VUE_APP_UserManagementDomain;
  api.clientMasterDataDomain = process.env.VUE_APP_ClientMasterDataDomain;
  api.vacationManagementDomain = process.env.VUE_APP_VacationManagementDomain;
  api.stockManagementDomainNotification = process.env.VUE_APP_StockManagementDomainNotification;
  api.financeDomain = process.env.VUE_APP_FinanceDomain;


  AWSurl.bucketName = process.env.VUE_APP_BucketName;
  AWSurl.accessKeyId = process.env.VUE_APP_accessKeyId;
  AWSurl.secretAccessKey = process.env.VUE_APP_secretAccessKey;
  AWSurl.region = process.env.VUE_APP_region;

  client.clientName = process.env.VUE_APP_ClientName;
  client.stateType = process.env.VUE_APP_stateType;
  client.clientLogo = process.env.VUE_APP_clientLogo
}

module.exports = {
  api,
  AWSurl,
  client,
};
